import React from "react"
import { graphql, StaticQuery} from "gatsby"
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import SEO from "../components/seo"
import renderHTML from 'react-render-html';

import "../utils/css/components/job.css"
import { EmptyComponent } from "../components/partials/base";

class JobPostTemplate extends React.Component {
    constructor(props) {
      super(props)
      this.parChild = React.createRef()
      this.parChildDetails = React.createRef()
    }

    render() {  
      const siteTitle = this.props.data.site.siteMetadata.title

      const job = this.props.data.jobNode;

      return (
        <LayoutV2
          location={this.props.location}
          title={siteTitle}
          headerActive={`Careers`}
        >
        <SEO title={job.title}/>
          <div className="job-page">
            <TitleSection frontmatter={job}/>
            <article className="job-description-div animated fadeIn">{renderHTML(job.description)}</article>
            <ApplyForThisJob href={job.joblink}/>
          </div>
        </LayoutV2>
      )
    }
}

class ApplyForThisJob extends React.Component {
    render () {
        const {href} = this.props;
        return <div className={`job-page-apply-div`}>
            <a className={`p-link p-link--btn`} href={href} target={`_blank`}>APPLY FOR THIS JOB</a>
        </div>
    }
}

// class SimilarJobsDiv extends React.Component {
//     render () {
//         const {specialization, id} = this.props.job;
//         return <StaticQuery
//             query={indexQuery}
//             render={data => (
//             <AboutPage location={props.location} data={data} {...props} />
//             )}
//         />
//     }
// }

// class SimilarJobsUI exends React.Component

class TitleSection extends React.Component {
    render() {  
        const {title, location, type, specialization, date_created} = this.props.frontmatter;
        return (
            <div className="job-title-div">
                <div className="job-title-div--specialization">
                    {specialization}
                </div>
                <div className="job-title-div--title">
                    {title}
                </div>
                <div className="job-title-div--desc">
                    <span className="job-title-div--desc-location">
                        {location}
                    </span>
                     • 
                    <span className="job-title-div--desc-type">
                        {type}
                    </span>
                    • 
                    <span className="job-title-div--desc-date">
                        Posted {date_created}
                    </span>
                </div>
            </div>
        )
    }
}  
export default JobPostTemplate

export const pageQuery = graphql`
  query BetaJobBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    jobNode (slug: {eq: $slug}){
        id
        date_created(fromNow: true)
        date_update
        description
        location
        specialization
        title
        type
    }
  }
`
